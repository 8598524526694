import React, { useRef, useState } from "react";

import {
	priceMax,
	priceMin,
	sizeMax,
	sizeMin,
} from "../../../constant/userManagement";
import ZipCodeViewer from "../zipcode-viewer";
import { getFormattedZipCodes } from "./helper";

const MarketSlider = ({ data = [], marketType = null, loggedInAs }) => {
	const [selectedMarketView, setSelectedMarketView] = useState(null)
	const slideBody = useRef();

	const renderItem = (label, value) => {
		return (
			<div className="form-group row flex-row m-0 align-items-center mb-1">
				<div className="col-5 py-0 form-label pr-3">{label}</div>
				<div className="col-7 form-value break-word">{value}</div>
			</div>
		);
	};

	const getLabelOfValue = (value, list) => {
		const item = list.filter((item) => item.value === value);
		return item && item.length ? item[0].label : value;
	};

	const renderData = () => {
		return data.map((item, index) => {
			const {
				name,
				zipCodes,
				propMinVal,
				propMaxVal,
				propMinSize,
				propMaxSize,
			} = item;


			const { zipCodesPreview, zipCodeViewMore } = getFormattedZipCodes(zipCodes)
			return (
				<div
					key={index}
					className={`market-slider-item`}
					data-title={`${marketType === 2 ? "SUB" : ""}MARKET ${index + 1
						}`}>
					<div className="market-slider-box">
						<div className="mb-1">
							<strong>{name}</strong>
						</div>
						<div className="form-group row flex-row m-0 align-items-center mb-1">
							<div className="col-5 py-0 form-label pr-3">Zip Codes</div>
							<div className="zip-code-button col-7 form-value break-word"
								onClick={() => setSelectedMarketView({
									name,
									zipCodes
								})}
							>{zipCodesPreview} <span className="text-primary">{zipCodeViewMore}</span></div>
						</div>


						{renderItem(
							"Property Price",
							`${getLabelOfValue(
								propMinVal,
								priceMin
							)} - ${getLabelOfValue(propMaxVal, priceMax)}`
						)}
						{renderItem(
							"Property Size",
							`${getLabelOfValue(
								propMinSize,
								sizeMin
							)} - ${getLabelOfValue(propMaxSize, sizeMax)}`
						)}
					</div>
				</div>
			);
		});
	};



	return data && data.length ? (
		<div className="market-slider-wrap">
			<div className="market-slider-container">
				<div className="market-slider-header">
					<h2 className="mr-2 flex-fill">
						Available{" "}
						{marketType === 2 && loggedInAs("SM")
							? "Submarkets"
							: "Markets"}
					</h2>
				</div>
				<div className="market-slider-body">
					<div className="market-slider" ref={slideBody}>
						{renderData()}
						<ZipCodeViewer
							selectedMarketView={selectedMarketView}
							setSelectedMarketView={setSelectedMarketView}
						/>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default MarketSlider;
