import { successMessage } from "./successMessage";

export const copyToClipboard = async (text) => {
    if (!text) return false;

    if (window?.navigator?.clipboard?.writeText) {
        await window.navigator.clipboard.writeText(text);
        return true;
    }

    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    const successful = document.execCommand('copy');
    textArea.remove();
    successMessage(3005)
    return successful;
};