import React, { useState } from "react";
import Select from "react-select";
import { Currency } from "../../../utils/formatter";
import { getFormattedZipCodes } from "../../markets/listing/helper";
import ZipCodeViewer from "../../markets/zipcode-viewer";
const RealtorListingCoverage = ({
	data,
	initialValue = null,
	onChange,
	marketList,
	loggedInAs,
}) => {
	const [selectedMarketView, setSelectedMarketView] = useState(null)
	const { zipCodesPreview, zipCodeViewMore } = getFormattedZipCodes(data?.zipCodes || [], 8)
	return (
		<div className="listing-coverage-block mb-4">
			<h2 className="mb-3">Listing Coverage</h2>
			{data ? (
				<div className="card card-primary p-3">
					<div className="d-flex mb-3">
						<h3 className="color-1 text-uppercase py-2 m-0 flex-fill">
							{!loggedInAs("AD") ? "In Process " : " "}Market
							Details
						</h3>
						<div className="filter-block form-group m-0">
							<Select
								value={initialValue}
								onChange={onChange}
								options={marketList}
								className="custom-select-box"
								placeholder={"Select"}
								isDisabled={false}
							/>
						</div>
					</div>
					<div className="card card-info  flex-row">
						<div className="coverage-item text-center form-group mb-0 justify-content-end">
							<div className="form-value zip-code-button"
								onClick={() => setSelectedMarketView({
									zipCodes: data.zipCodes
								})}
							>{zipCodesPreview}{" "}<span className="text-primary">{zipCodeViewMore}</span></div>
							<div className="form-label color-1">Zip Codes</div>
						</div>
						<div className="coverage-item text-center form-group mb-0 justify-content-end">
							<div className="form-value">{data.rank}</div>
							<div className="form-label color-1">Rank</div>
						</div>
						<div className="coverage-item text-center form-group mb-0 justify-content-end">
							<div className="form-value">{data.newListing}</div>
							<div className="form-label color-1">
								{!loggedInAs("AD") ? "In Process " : " "}New
							</div>
						</div>
						<div className="coverage-item text-center form-group mb-0 justify-content-end">
							<div className="form-value">
								{data.activeListing}
							</div>
							<div className="form-label color-1">
								{!loggedInAs("AD") ? "In Process " : " "}Active
							</div>
						</div>
						<div className="coverage-item text-center form-group mb-0 justify-content-end">
							<div className="form-value">{data.pending}</div>
							<div className="form-label color-1">
								{!loggedInAs("AD") ? "In Process " : " "}Pending
							</div>
						</div>
						<div className="coverage-item text-center form-group mb-0 justify-content-end">
							<div className="form-value">
								<Currency
									amount={data.totalListingValue}
									currency="USD"
								/>
							</div>
							<div className="form-label color-1">
								{!loggedInAs("AD") ? "In Process " : "Total "}
								Value
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="alert alert-info p-3 text-center">Loading</div>
			)}
			<ZipCodeViewer
				selectedMarketView={selectedMarketView}
				setSelectedMarketView={setSelectedMarketView}
			/>
		</div>
	);
};

export default RealtorListingCoverage;
