import React, { useState } from "react"
import { copyToClipboard } from "../../utils/copyToClipboard"
import InfoModal from "../../utils/infoModal"
import SearchField from "../../utils/searchTable"

const ZipCodeViewer = ({
    selectedMarketView,
    setSelectedMarketView
}) => {
    const [searchKey, setSearchKey] = useState('')
    const filteredZip = selectedMarketView?.zipCodes
        .split(",")
        .filter((zip) => !searchKey || zip.includes(searchKey))
        .map(zip => zip.trim());
    return <InfoModal
        className="view-template-modal"
        show={selectedMarketView !== null}
        onYes={() => setSelectedMarketView(null)}
        title={
            <>
                <div className="zipcode-modal-header">
                    <div>
                        {selectedMarketView?.name} Zip Codes
                    </div>
                    <button className="btn btn-primary btn-md"
                        onClick={async () => await copyToClipboard(selectedMarketView?.zipCodes)}
                    >
                        Copy zipcodes
                    </button>
                </div>
                <SearchField
                    initialValue={searchKey}
                    onSearch={(searchKey) => setSearchKey(searchKey)}
                    className={`flex-fill mr-3 form-group mb-0`}
                    placeholder="Enter your query"
                />
            </>
        }
        message={<div>
            {
                filteredZip && filteredZip.length > 0 ?
                    filteredZip.map(zip => (
                        <div
                            className="badge badge-secondary badge-sm mr-2 mb-2"
                            key={zip}>
                            {zip}
                        </div>
                    ))

                    : "No zip codes found."
            }
        </div>}
    />
}

export default ZipCodeViewer