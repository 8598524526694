import React from "react";
import { outboundCommunicationType } from "../../../constant/templateManagement";
import {
	priceMax,
	priceMin,
	sizeMax,
	sizeMin,
} from "../../../constant/userManagement";
import Tooltip from "../../../utils/tooltip";


export const getFormattedZipCodes = (zipCodes, length = 3) => {
	const isZipCodesArray = Array.isArray(zipCodes)

	const zipCodesArray = isZipCodesArray ? zipCodes : zipCodes.split(",")

	if (zipCodesArray.length <= length) return {
		zipCodesPreview: isZipCodesArray ? zipCodes.join(", ") : zipCodes,
		zipCodeViewMore: ''
	}

	return {
		zipCodesPreview: zipCodesArray.slice(0, length).map(zipCode => zipCode.trim()).join(", "),
		zipCodeViewMore: `...${zipCodesArray.length - length} more`

	}
}

export const columns = ({
	toggleAddEditUserPanel,
	selectMarket,
	onDelete,
	loggedInAs,
	listType = 0,
	setSelectedMarketView
}) => {
	const getLabel = (value, collection) => {
		const obj = collection.filter((item) => item.value === value);
		return obj && obj.length ? obj[0].label : value;
	};

	return [
		{
			name: "Name",
			accessor: "marketName",
			className: "col-market-name",
			headerClassName: "col-market-name-header",
			render: (row) => (
				<div
					className="text-primary pointer ellipsis"
					onClick={() => selectMarket(row)}>
					{row.marketName || row.subMarketName}
				</div>
			),
		},
		{
			name: "County",
			accessor: "county",
			className: "col-market-county",
			headerClassName: "col-market-county-header",
			sortable: false,
			hide: !loggedInAs("AD") || listType !== 0,
		},
		{
			name: "Category",
			accessor: "category",
			className: "col-market-category",
			headerClassName: "col-market-category-header",
			hide: loggedInAs("AD") && listType === 0,
		},
		{
			name: "Zip Code",
			accessor: "zipCodes",
			className: "col-market-zip-codes",
			headerClassName: "col-market-zip-codes-header",
			sortable: false,
			render: (row) => {
				const zipCodes = row.zipCodes
				const { zipCodesPreview, zipCodeViewMore } = getFormattedZipCodes(zipCodes)
				return <div className="zip-code-button"
					onClick={() => setSelectedMarketView({
						name: row.subMarketName,
						zipCodes: row.zipCodes

					})}
				>
					{zipCodesPreview} <br /><span className="text-primary">{zipCodeViewMore}</span>
				</div>
			}
		},
		{
			name: "Size",
			accessor: "propMinSize",
			className: "col-market-property-size",
			headerClassName: "col-market-property-size-header",
			render: (row) => (
				<>
					{getLabel(row.propMinSize, sizeMin)}-
					{getLabel(row.propMaxSize, sizeMax)}
				</>
			),
			sortable: false,
		},
		{
			name: "Price",
			accessor: "propMinVal",
			className: "col-market-property-val",
			headerClassName: "col-market-property-val-header",
			render: (row) => (
				<>
					{getLabel(row.propMinVal, priceMin)}-
					{getLabel(row.propMaxVal, priceMax)}
				</>
			),
			sortable: false,
		},
		{
			name: "Owner",
			accessor: "owner",
			className: "col-market-owner",
			headerClassName: "col-market-owner-header",
			sortable: !loggedInAs("AD"),
		},
		{
			name: "# of listings",
			accessor: "numListings",
			className: "col-market-county",
			headerClassName: "col-market-county-header",
		},
		{
			name: "Actions",
			className: "col-actions text-right",
			headerClassName: "col-actions-header",
			sortable: false,
			hide: listType !== 0,
			render: (row) => (
				<div>
					<Tooltip
						message="Edit"
						component={
							<button
								type="button"
								className="btn btn-cell btn-default"
								onClick={() =>
									toggleAddEditUserPanel &&
									toggleAddEditUserPanel(row)
								}>
								<i className="icon icon-edit" />
							</button>
						}
					/>
					<Tooltip
						message="Delete"
						component={
							<button
								type="button"
								className="btn btn-cell btn-default ml-2"
								onClick={() => onDelete(row)}>
								<i className="icon icon-trash" />
							</button>
						}
					/>
				</div>
			),
		},
	];
};

export const getMarketNotificationData = (marketData) => {
	if (
		typeof marketData.subMarketNotificationSettings === "undefined" ||
		!marketData.subMarketNotificationSettings.length
	)
		return { notificationData: true };
	let filentObject = { notificationData: true };
	const ownerObject = marketData.subMarketNotificationSettings.filter(
		(item) =>
			item.outboundProspectingType ===
			outboundCommunicationType.homeOwner.value
	)[0];

	filentObject.ownerTurnOffAutomaticProspecting =
		ownerObject.turnOffAutomaticProspecting;

	ownerObject.templateMappings.forEach((element) => {
		filentObject[
			`select${2}emailTemplate${element.notificationEventTypeId}`
		] = { value: element.emailTemplateId ? element.emailTemplateId : "" };

		filentObject[
			`select${2}mailerTemplate${element.notificationEventTypeId}`
		] = { value: element.mailerTemplateId ? element.mailerTemplateId : "" };

		filentObject[
			`select${2}smsTemplate${element.notificationEventTypeId}`
		] = { value: element.smsTemplateId ? element.smsTemplateId : "" };
	});

	const relatorObject = marketData.subMarketNotificationSettings.filter(
		(item) =>
			item.outboundProspectingType ===
			outboundCommunicationType.realtor.value
	)[0];

	filentObject.realtorTurnOffAutomaticProspecting =
		relatorObject.turnOffAutomaticProspecting;

	relatorObject.templateMappings.forEach((element) => {
		filentObject[
			`select${1}emailTemplate${element.notificationEventTypeId}`
		] = { value: element.emailTemplateId ? element.emailTemplateId : "" };

		filentObject[
			`select${1}mailerTemplate${element.notificationEventTypeId}`
		] = { value: element.mailerTemplateId ? element.mailerTemplateId : "" };

		filentObject[
			`select${1}smsTemplate${element.notificationEventTypeId}`
		] = { value: element.smsTemplateId ? element.smsTemplateId : "" };
	});

	return filentObject;
};
