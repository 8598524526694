import { get } from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { connect } from "react-redux";
import { change, Field, getFormValues, isDirty, reduxForm } from "redux-form";

import { getMarketNotificationSettings, marketFields } from "./helper";

import { getMarketNotificationData } from "../listing/helper";

import {
  checkAvailability,
  clearCompanyMarkets,
  createMarket,
  getCompanyMarkets,
  getCompanySubMarkets,
  getMarket,
  getMarketCompanies,
  getMarketZipcodes,
  setAutoProspectingInitial,
  toggleMainLoader,
  updateMarket,
} from "../../../store/actions";

import { errorMessage } from "../../../utils/errorMessage";
import { successMessage } from "../../../utils/successMessage";

import FormGroup from "../../../utils/formGroup";
import Tooltip from "../../../utils/tooltip";

import ZipCodeViewer from "../zipcode-viewer";
import AutomaticProspectingForm from "./AutomaticProspectingForm";

const initialStates = {
  error: null,
  available: false,
  availableList: [],
  submitting: false,
};

const reducer = (state, action) => {
  return { ...state, ...action };
};

const CreateMarket = ({
  initialValues = null,
  loggedInAs,
  createMode,
  handleSubmit,
  formValues,
  availableMarkets = [],
  dirtyForm,
  dirtyFormAutomaticProspecting,
  getMarketZipcodes,
  toggleMainLoader,
  getMarketCompanies,
  marketZipcodes,
  marketCompanies,
  companyMarkets,
  companySubMarkets,
  getCompanyMarkets,
  getCompanySubMarkets,
  checkAvailability,
  userId,
  createMarket,
  updateMarket,
  onEdit = null,
  onCancel = null,
  onCreate = null,
  change,
  userDetails,
  ownerTemplateProspectingEvents,
  relatorTemplateProspectingEvents,
  templateList,
  autoFromValues,
  getMarket,
  setAutoProspectingInitial,
  autoProspectingInitial,
  clearCompanyMarkets,
}) => {
  const [showEditZipCodes, setShowEditZipCodes] = useState(false)
  const [selectedMarketView, setSelectedMarketView] = useState(null)
  const [state, dispatch] = useReducer(reducer, initialStates);
  const { error, available, availableList, submitting } = state;

  const [panel, setPanel] = useState(1);
  const [updateProspectingInitial, setUpdateProspectingInitial] = useState(0);
  const zipRef = useRef("");
  useEffect(() => {
    loggedInAs("AD") && getMarketCompanies();
  }, [getMarketCompanies, loggedInAs]);

  useEffect(() => {
    loggedInAs("AD") && getMarketZipcodes();
  }, [getMarketZipcodes, loggedInAs]);

  useEffect(() => {
    !loggedInAs("AD") && getCompanyMarkets({ companyUserId: userId });
  }, [getCompanyMarkets, loggedInAs, userId]);

  useEffect(() => {
    return () => {
      clearCompanyMarkets()
    }
  }, [])
  useEffect(() => {
    dispatch({
      availableList: loggedInAs("SM")
        ? availableMarkets.map((item) => {
          return {
            label: item.name,
            value: item.subMarketId,
            marketId: item.marketId,
          };
        })
        : [],
    });
  }, [availableMarkets, loggedInAs]);

  useEffect(() => {
    if (initialValues) {
      if (initialValues.subMarketType == 2 && loggedInAs("CA")) {
        getMarketCompanies({
          marketType: initialValues.subMarketType,
          subMarketId: initialValues.parentSubMarketId,
        });
      }
      else {
        getMarketCompanies({
          marketType: initialValues.subMarketType,
        });
      }
    }
    if (initialValues && initialValues.marketId) {
      getCompanySubMarkets(initialValues.marketId);
      getMarketZipcodes({
        marketId: !loggedInAs("AD") ? initialValues.marketId : undefined,
        subMarketId:
          !loggedInAs("AD") && initialValues.subMarketType === 2
            ? initialValues.parentSubMarketId
            : undefined,
        submarketType: 1,
      });
    }
  }, [
    initialValues,
    getMarketCompanies,
    getCompanySubMarkets,
    getMarketZipcodes,
    loggedInAs,
  ]);

  const noChanges = () => {
    toggleMainLoader(false)
    onCancel()
    errorMessage("NO_CHANGE");
    dispatch({ submitting: false });
  };

  const submitForm = (values = null) => {
    toggleMainLoader(true);
    dispatch({ submitting: true });
    const successHandler = (event) => {
      toggleMainLoader(false);
      dispatch({ submitting: false });
    };
    const errorHandler = (event) => {
      toggleMainLoader(false);
      errorMessage(event);
      dispatch({ submitting: false });
    };

    if (createMode) {
      const createData = {
        ...values,
        name: values.itemName,
        zipCodes: values.zipCodes
          ? values.zipCodes.map((item) => (item.label ? item.label : item))
          : [],
        propMinVal:
          values.propMinVal &&
            (values.propMinVal.value || values.propMinVal.value === 0)
            ? values.propMinVal.value
            : values.propMinVal,
        propMaxVal:
          values.propMaxVal &&
            (values.propMaxVal.value || values.propMaxVal.value === 0)
            ? values.propMaxVal.value
            : values.propMaxVal,
        propMinSize:
          values.propMinSize &&
            (values.propMinSize.value || values.propMinSize.value === 0)
            ? values.propMinSize.value
            : values.propMinSize,
        propMaxSize:
          values.propMaxSize &&
            (values.propMaxSize.value || values.propMaxSize.value === 0)
            ? values.propMaxSize.value
            : values.propMaxSize,

        companyUserIds: loggedInAs("AD")
          ? values.companyUserIds
            ? values.companyUserIds.map((item) =>
              item.value ? item.value : item
            )
            : values.companyUsers
          : null,

        subMarketType: loggedInAs("SM")
          ? 2
          : values.subMarketType && values.subMarketType.value
            ? values.subMarketType.value
            : values.subMarketType,
        marketId:
          loggedInAs("SM") && values && values.parentSubMarketId
            ? values.parentSubMarketId.marketId
            : values.marketUserId && values.marketUserId.value
              ? values.marketUserId.value
              : values.marketUserId,

        marketUserId:
          values.companyUserIds && values.companyUserIds.value
            ? values.companyUserIds.value
            : values.companyUserIds,

        parentSubMarketId:
          values.parentSubMarketId && values.parentSubMarketId.value
            ? values.parentSubMarketId.value
            : values.parentSubMarketId,
        automaticProspecting: values.automaticProspecting,
        subMarketNotificationSettings: getMarketNotificationSettings(
          { ...values, ...autoFromValues },
          ownerTemplateProspectingEvents,
          relatorTemplateProspectingEvents,
          createMode
        ),
      };
      createMarket(createData, successHandler, errorHandler);
      onCreate()
    } else {
      const {
        marketId,
        itemName,
        zipCodes,
        isActive,
        county: countyValue,
        subMarketId,
        subMarketType,
        marketUserId,
        parentSubMarketId,
        automaticProspecting,
        propMinVal,
        propMaxVal,
        propMinSize,
        propMaxSize
      } = values;


      const county = countyValue ? { county: countyValue } : {};

      const companyUserIds = values.companyUserIds
        ? Array.isArray(values.companyUserIds)
          ? values.companyUserIds.map((item) => {
            if (typeof item === "object") {
              return item.value;
            }
            const result = marketCompanies.filter(
              (company) => company.fullName === item
            );
            return result && result.length && result[0].userId;
          })
          : [values.companyUserIds.value]
        : [];

      const editData = {
        marketId,
        name: itemName,
        zipCodes: zipCodes
          ? zipCodes.map((item) => (item.label ? item.label : item))
          : [],
        isActive,
        ...county,
        subMarketId,
        subMarketType,
        marketUserId: marketUserId
          ? marketUserId
          : companyUserIds && companyUserIds.length
            ? companyUserIds[0]
            : "",
        parentSubMarketId,
        automaticProspecting,
        companyUserIds,
        subMarketNotificationSettings: getMarketNotificationSettings(
          { ...values, ...autoFromValues },
          ownerTemplateProspectingEvents,
          relatorTemplateProspectingEvents,
          createMode
        ),
        propMinVal:
          propMinVal &&
            (propMinVal.value || propMinVal.value === 0)
            ? propMinVal.value
            : propMinVal,
        propMaxVal:
          propMaxVal &&
            (propMaxVal.value || propMaxVal.value === 0)
            ? propMaxVal.value
            : propMaxVal,
        propMinSize:
          propMinSize &&
            (propMinSize.value || propMinSize.value === 0)
            ? propMinSize.value
            : propMinSize,
        propMaxSize:
          propMaxSize &&
            (propMaxSize.value || propMaxSize.value === 0)
            ? propMaxSize.value
            : propMaxSize,

      };
      dirtyForm || dirtyFormAutomaticProspecting
        ? updateMarket(editData, successHandler, errorHandler)
        : noChanges();
      onEdit()
    }
  };


  const checkAvailabilityFn = () => {
    const getFields = () => {
      if (!formValues) return null
      if (!createMode) return {
        name: formValues.itemName || null,
        subMarketId: formValues.itemId ? formValues.itemId : null,
        marketId: formValues.marketId
          ? formValues.marketId
          : formValues.parentSubMarketId
            ? formValues.parentSubMarketId
            : null,
        marketUserId: formValues.marketUserId
          ? formValues.marketUserId.value
          : formValues.parentSubMarketId
            ? formValues.parentSubMarketId.marketId
            : null,
        subMarketType: loggedInAs("SM")
          ? 2
          : formValues.subMarketType
            ? formValues.subMarketType
            : 1,
        zipCodes: formValues.zipCodes
          ? formValues.zipCodes.map((item) => item?.label || item)
          : [],
        propMinVal: formValues.propMinVal ? formValues.propMinVal?.value || formValues.propMinVal : 0,
        propMaxVal: formValues.propMaxVal ? formValues.propMaxVal?.value || formValues.propMaxVal : 0,
        propMinSize: formValues.propMinSize
          ? formValues.propMinSize?.value || formValues.propMinSize
          : 0,
        propMaxSize: formValues.propMaxSize
          ? formValues.propMaxSize?.value || formValues.propMaxSize
          : 0,
        parentSubMarketId: formValues.parentSubMarketId
          ? formValues.parentSubMarketId
          : null,
        automaticProspecting: formValues.automaticProspecting || false,
      }

      return {
        name: formValues.itemName || null,
        marketId: formValues.marketUserId
          ? formValues.marketUserId.value
          : formValues.parentSubMarketId
            ? formValues.parentSubMarketId.marketId
            : null,
        marketUserId: formValues.marketUserId
          ? formValues.marketUserId.value
          : formValues.parentSubMarketId
            ? formValues.parentSubMarketId.marketId
            : null,
        subMarketType: loggedInAs("SM")
          ? 2
          : formValues.subMarketType
            ? formValues.subMarketType.value
            : 1,
        zipCodes: formValues.zipCodes
          ? formValues.zipCodes.map((item) => item.label)
          : [],
        propMinVal: formValues.propMinVal ? formValues.propMinVal.value : 0,
        propMaxVal: formValues.propMaxVal ? formValues.propMaxVal.value : 0,
        propMinSize: formValues.propMinSize
          ? formValues.propMinSize.value
          : 0,
        propMaxSize: formValues.propMaxSize
          ? formValues.propMaxSize.value
          : 0,
        parentSubMarketId: formValues.parentSubMarketId
          ? formValues.parentSubMarketId.value
          : null,
        automaticProspecting: formValues.automaticProspecting || false,
      }
    }

    const fields = getFields()
    const successHandler = (e) => {
      if (e && e.result && !e.result.responseValue) {
        errorMessage(e, renderReason);
      } else {
        successMessage(3004);
        dispatch({ available: true });
      }
    };
    const errorHandler = (event) => {
      errorMessage(event);
      dispatch({ available: false });
    };
    if (fields) {
      checkAvailability(fields, successHandler, errorHandler);
    }
  };

  const renderReason = (e) => {
    const { referenceDetails } = e;
    return (
      <div>
        <h3>Not available!</h3>
        <div>Overlapping with following submarket/ bucket</div>
        <ul className="list-bullet">
          {referenceDetails.map((item, index) => (
            <li key={index}>{item.marketName}</li>
          ))}
        </ul>
      </div>
    );
  };

  const onChangeParentSubMarket = (subMarketID) => {
    const successHandler = (e) => {
      if (createMode) {
        const notification = getMarketNotificationData(e.result);
        setAutoProspectingInitial(notification);

        for (const [key, value] of Object.entries(notification)) {
          if (key && key.includes("select") && value.value !== "") {
            change(key, { value: value.value });
          }
        }

        change("automaticProspecting", e.result.automaticProspecting);
        setUpdateProspectingInitial(!updateProspectingInitial);
      }
    };

    getMarket({ subMarketId: subMarketID }, successHandler);
  };

  return (
    <form className="create-user-form" onSubmit={handleSubmit(submitForm)}>
      <div className="panel-switch-wrap">
        <div className={`panel-switch ${panel === 1 ? "active" : ""}`}>
          <h2 className="mb-4">
            {createMode ? "New" : "Edit"}{" "}
            {loggedInAs("AD")
              ? "Market"
              : loggedInAs("CA")
                ? "Child Market"
                : "Bucket"}
          </h2>
          {!createMode && !initialValues ? (
            <div className="alert alert-info text-center p-3">Getting data</div>
          ) : (
            <>
              <div className="form-container">
                <div className="row">
                  {marketFields({
                    dispatch,
                    loggedInAs,
                    createMode,
                    marketZipcodes,
                    marketCompanies,
                    companyMarkets,
                    companySubMarkets,
                    getCompanySubMarkets,
                    checkAvailabilityFn,
                    getMarketZipcodes,
                    getMarketCompanies,
                    availableList,
                    formValues,
                    initialValues,
                    change,
                    templateList,
                    setPanel,
                    onChangeParentSubMarket,
                    zipRef,
                    showEditZipCodes,
                    setShowEditZipCodes,
                    setSelectedMarketView
                  }).map((item, index) => (
                    <Field key={index} {...item} component={FormGroup} />
                  ))}

                  {formValues && formValues.automaticProspecting ? (
                    <Tooltip
                      message="Set template"
                      component={
                        <button
                          type="button"
                          className="btn btn-default btn-cell"
                          onClick={() => setPanel(2)}
                        >
                          <i className="icon icon-template" />
                        </button>
                      }
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-footer">
                <button
                  className="btn btn-default mt-3 mr-2"
                  type="button"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary mt-3"
                  type="submit"
                  disabled={
                    (!available && !loggedInAs("AD")) ||
                    submitting
                  }
                >
                  Submit
                </button>
                {error && (
                  <div className="error small text-danger text-center mt-3">
                    {error}
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <AutomaticProspectingForm
          {...{
            initialValuesData: initialValues,
            createMode,
            ownerTemplateProspectingEvents,
            relatorTemplateProspectingEvents,
            templateList,
            formValues,
            setPanel,
            panel,
            autoFromValues,
            initialValues: createMode ? null : autoProspectingInitial,
            updateProspectingInitial,
          }}
        />
      </div>
      <ZipCodeViewer
        selectedMarketView={selectedMarketView}
        setSelectedMarketView={setSelectedMarketView}
      />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues("CreateMarketForm")(state),
    autoFromValues: getFormValues("AutomaticProspectingForm")(state),
    marketZipcodes: get(state, "Market.marketZipcodes", []),
    marketCompanies: get(state, "Market.marketCompanies", []),
    autoProspectingInitial: get(state, "Market.propspectinginitialValues", []),
    userId: get(state, "Auth.userDetails.userId", null),
    userDetails: get(state, "Auth.userDetails", null),
    companyMarkets: get(state, "Market.companyMarkets", []),
    companySubMarkets: get(state, "Market.companySubMarkets", []),
    dirtyForm: isDirty("CreateMarketForm")(state),
    dirtyFormAutomaticProspecting: isDirty("AutomaticProspectingForm")(state),
  };
};

const mapDispatchToProps = {
  toggleMainLoader,
  getMarketZipcodes,
  getMarketCompanies,
  getCompanyMarkets,
  getCompanySubMarkets,
  checkAvailability,
  createMarket,
  updateMarket,
  change,
  getMarket,
  setAutoProspectingInitial,
  clearCompanyMarkets,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "CreateMarketForm",
    enableReinitialize: true
  })(CreateMarket)
);
