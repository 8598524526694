import React, { useEffect, useState } from "react";
import Tooltip from "../../utils/tooltip";
import "./NavbarToggle.scss";

const NAVBAR_STATE_KEY = 'moovsoon_navbar_collapsed';

const NavbarToggle = () => {
    const [isCollapsed, setIsCollapsed] = useState(() => {
        const saved = localStorage.getItem(NAVBAR_STATE_KEY);
        return saved ? JSON.parse(saved) : true;
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const saved = localStorage.getItem(NAVBAR_STATE_KEY);
            setIsCollapsed(saved ? JSON.parse(saved) : true);
        };

        window.addEventListener('navbar-toggle', handleStorageChange);
        return () => window.removeEventListener('navbar-toggle', handleStorageChange);
    }, []);

    const toggleNavbar = () => {
        const newState = !isCollapsed;
        setIsCollapsed(newState);
        localStorage.setItem(NAVBAR_STATE_KEY, JSON.stringify(newState));
        window.dispatchEvent(new Event('navbar-toggle'));
    };

    return (
        <Tooltip
            message={isCollapsed ? "Expand Navigation" : "Hide Navigation"}
            placement="right"
            component={
                <button
                    className="btn btn-cell btn-40 btn-light ml-3 mr-3 toggle-btn"
                    onClick={toggleNavbar}
                    aria-label="Toggle navbar"
                >
                    {isCollapsed ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-layout-sidebar-left-expand">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                            <path d="M9 4v16" />
                            <path d="M14 10l2 2l-2 2" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-layout-sidebar-left-collapse">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                            <path d="M9 4v16" />
                            <path d="M15 10l-2 2l2 2" />
                        </svg>
                    )}
                </button>
            }
        />

    );
};

export default NavbarToggle;