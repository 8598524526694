export const userRoles = [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "CompanyAdmin",
    label: "Company Admin",
  },
  {
    value: "SalesManager",
    label: "Submarket Owner",
  },
  {
    value: "SalesPerson",
    label: "Bucket owner",
  },
];

export const getUserTypeName = (event) => {
  const userType = userRoles.filter((item) => item.value === event);
  return userType && userType.length ? userType[0].label : null;
};

export const userTypes = {
  AD: "Admin",
  CA: "CompanyAdmin",
  SM: "SalesManager",
  BO: "SalesPerson",
};

export const priceMin = [
  { label: "$0", value: 0 },
  { label: "$100k", value: 100001 },
  { label: "$200k", value: 200001 },
  { label: "$250k", value: 250001 },
  { label: "$300k", value: 300001 },
  { label: "$350k", value: 350001 },
  { label: "$400k", value: 400001 },
  { label: "$500k", value: 500001 },
  { label: "$600k", value: 600001 },
  { label: "$700k", value: 700001 },
  { label: "$800k", value: 800001 },
  { label: "$900k", value: 900001 },
  { label: "$1M", value: 1000001 },
  { label: "$1.1M", value: 1100001 },
  { label: "$1.2M", value: 1200001 },
  { label: "$1.3M", value: 1300001 },
  { label: "$1.4M", value: 1400001 },
  { label: "$1.5M", value: 1500001 },
  { label: "$1.6M", value: 1600001 },
  { label: "$1.7M", value: 1700001 },
  { label: "$1.8M", value: 1800001 },
  { label: "$1.9M", value: 1900001 },
  { label: "$2M", value: 2000001 },
];

export const priceMax = [
  { label: "$100k", value: 100000 },
  { label: "$200k", value: 200000 },
  { label: "$250k", value: 250000 },
  { label: "$300k", value: 300000 },
  { label: "$350k", value: 350001 },
  { label: "$400k", value: 400000 },
  { label: "$500k", value: 500000 },
  { label: "$600k", value: 600000 },
  { label: "$700k", value: 700000 },
  { label: "$800k", value: 800000 },
  { label: "$900k", value: 900000 },
  { label: "$1M", value: 1000000 },
  { label: "$1.1M", value: 1100000 },
  { label: "$1.2M", value: 1200000 },
  { label: "$1.3M", value: 1300000 },
  { label: "$1.4M", value: 1400000 },
  { label: "$1.5M", value: 1500000 },
  { label: "$1.6M", value: 1600000 },
  { label: "$1.7M", value: 1700000 },
  { label: "$1.8M", value: 1800000 },
  { label: "$1.9M", value: 1900000 },
  { label: "$2M", value: 2000000 },
  { label: "Any Price", value: 10000000000000 },
];

export const sizeMin = [
  { label: "0", value: 0 },
  { label: "501 sqft", value: 501 },
  { label: "1,001 sqft", value: 1001 },
  { label: "1,501 sqft", value: 1501 },
  { label: "2,001 sqft", value: 2001 },
  { label: "3,001 sqft", value: 3001 },
  { label: "4,001 sqft", value: 4001 },
  { label: "6,001 sqft", value: 6001 },
];

export const sizeMax = [
  { label: "500 sqft", value: 500 },
  { label: "1,000 sqft", value: 1000 },
  { label: "1,500 sqft", value: 1500 },
  { label: "2,000 sqft", value: 2000 },
  { label: "3,000 sqft", value: 3000 },
  { label: "4,000 sqft", value: 4000 },
  { label: "6,000 sqft", value: 6000 },
  { label: "Any Size", value: 10000000000000 },
];
