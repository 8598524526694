export const successCodes = [
	{
		code: 1001,
		message: "Sign In successful!",
	},
	{
		code: 1002,
		message: "Password updated!",
	},
	{
		code: 1003,
		message: "Password changed! Please login again.",
	},
	{
		code: 1004,
		message: "Logged out successfully!",
	},
	// User management
	{
		code: 2001,
		message: "User has been created successfully!",
	},
	{
		code: 2002,
		message: "User has been updated successfully!",
	},
	{
		code: 2003,
		message: "User removed!",
	},
	// Market management
	{
		code: 3001,
		message:
			"Market/sub-market/bucket has been processed for creation! It might take a moment for the changes to be reflected, and up to an hour for the listings to be added."
	},
	{
		code: 3002,
		message:
			"Market/sub-market/bucket has been processed for update! Changes may take a moment to be reflected, and it might take up to an hour if new listings are to be added."
	},
	{
		code: 3003,
		message: "Market/sub-market/bucket has been processed for deletion! Changes may take a moment to be reflected",
	},
	{
		code: 3004,
		message: "Submarket/bucket available. Go ahead!",
	},
	{
		code: 3005,
		message: "Copied zipcodes to clipboard successfully!"
	},
	// Template management
	{
		code: 4001,
		message: "Template has been created successfully!",
	},
	{
		code: 4002,
		message: "Template has been updated successfully!",
	},
	{
		code: 4003,
		message: "Template removed!",
	},
	// Settings management
	{
		code: 5001,
		message: "Settings has been created successfully!",
	},
	{
		code: 5002,
		message: "Settings has been updated successfully!",
	},
	{
		code: 5003,
		message: "Settings removed!",
	},
	// Property management
	{
		code: 6001,
		message: "Automated Touches has been updated successfully!",
	},
	{
		code: 6002,
		message: "Email will be sent shortly!",
	},
	{
		code: 6003,
		message: "Listing marked as favorite!",
	},
	{
		code: 6004,
		message: "Listing has been discarded!",
	},
	{
		code: 6005,
		message: "Listing is In Process!",
	},
	{
		code: 6006,
		message: "Mailer will be sent shortly!",
	},
	{
		code: 6007,
		message: "SMS will be sent shortly!",
	},
	{
		code: 6008,
		message: "Track Conversion has been updated successfully!",
	},
	{
		code: 6009,
		message: "Listing is not In Process!",
	},
	{
		code: 6010,
		message: "Listing is not discarded!",
	},
	{
		code: 6011,
		message: "Listing not marked as favorite!",
	},
	{
		code: 7001,
		message: "Email Sent!",
	},
	{
		code: 7002,
		message: "Owner Data Requested"
	},
	{
		code: 8001,
		message: "Realtor has been updated successfully!",
	},
	{
		code: 5005,
		message: "Connected successfully. Please save the settings!",
	},
];
