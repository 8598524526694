import { NotificationManager } from "react-notifications";

import { successCodes } from "../constant/successCodes";

export const successMessage = (event, delay = 5000) => {
    let code = null;

    switch (typeof event) {
        case "number":
        case "string":
            code = event;
            break;
        default:
            const error = event.error || event.result;
            code = error && error.code;
    }

    if (code || code === 0) {
        const message = successCodes.filter((i) => i.code === code);
        return NotificationManager.success(
            message[0] ? message[0].message : "Success!",
            "",
            delay
        );
    }
    NotificationManager.success("Success!", "", delay);
};
