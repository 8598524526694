import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { NavLink } from "react-router-dom";
import MoovSoonLogo from "../../assets/Movsoon logo.png";
import MoovSoonBranding from "../../assets/branding bg.png";
import MoovSoonLogoSmall from "../../assets/moovsoon-logo-small.png";
import "./NavBar.scss";
import { navigationLinks } from "./helper";

const NAVBAR_STATE_KEY = 'moovsoon_navbar_collapsed';

const NavBar = ({ userData }) => {
    const [isCollapsed, setIsCollapsed] = useState(() => {
        const saved = localStorage.getItem(NAVBAR_STATE_KEY);
        return saved ? JSON.parse(saved) : true;
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const saved = localStorage.getItem(NAVBAR_STATE_KEY);
            setIsCollapsed(saved ? JSON.parse(saved) : true);
        };

        window.addEventListener('navbar-toggle', handleStorageChange);
        return () => window.removeEventListener('navbar-toggle', handleStorageChange);
    }, []);

    const oddEvent = (match, location, to) => {
        return location.pathname.startsWith(to);
    };



    return (
        <aside className={`moovsoon-navbar ${isCollapsed ? 'collapsed' : ''}`} style={{ isolation: 'isolate' }}>
            <Scrollbars autoHide={true}>
                <div className="navbar-container">
                    <div className="navbar-logo">
                        {isCollapsed ? (
                            <img src={MoovSoonLogoSmall} alt="MOOVSOON" className="logo logo-small" />
                        ) : (
                            <img src={MoovSoonLogo} alt="MOOVSOON" className="logo logo-full" />
                        )}
                    </div>
                    <nav className="navbar-nav">
                        <ul>
                            {navigationLinks({ userData }).map((nav, index) =>
                                nav.hide ? null : (
                                    <li key={index}>
                                        <NavLink
                                            to={nav.to}
                                            isActive={(match, location) =>
                                                oddEvent(match, location, nav.to)
                                            }
                                            activeClassName="active"
                                            title={nav.label}
                                        >
                                            <i className={`icon ${nav.icon}`} />
                                            <span className="nav-label">{nav.label}</span>
                                        </NavLink>
                                    </li>
                                )
                            )}
                        </ul>
                    </nav>
                    {
                        !isCollapsed &&
                        <div className="navbar-branding">
                            <img src={MoovSoonBranding} alt="Branding" />
                        </div>
                    }
                </div>
            </Scrollbars>
        </aside>
    );
};

export default NavBar;